const ACCESS_TOKEN_KEY = 'dmarcwise/accessToken';
const IS_AUTHENTICATED_KEY = 'dmarcwise/authenticated';
const ORGANIZATION_ID_KEY = 'dmarcwise/organizationId';

/*
 * Authentication
 */
export const isAuthenticated = () => {
	return localStorage.getItem(IS_AUTHENTICATED_KEY) === 'true';
};

export const setAuthenticated = (isAuthenticated: boolean) => {
	localStorage.setItem(IS_AUTHENTICATED_KEY, isAuthenticated.toString());
};

export const hasLegacyAccessToken = () => {
	return localStorage.getItem(ACCESS_TOKEN_KEY) != null;
};

export const removeAccessToken = () => {
	localStorage.removeItem(ACCESS_TOKEN_KEY);
};

/*
 * Organization ID
 */
export const getOrganizationId = () => {
	return localStorage.getItem(ORGANIZATION_ID_KEY);
};
